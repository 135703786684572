import React from 'react';
import './Main_gift_flow.css';

import {  Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import ReCAPTCHA from "react-google-recaptcha";


import Tracker from '../../../layout_components/Tracker/Tracker.js';
import Gifting_step_1 from '../Gifting_step_1/Gifting_step_1.js';
import Gifting_step_2 from '../Gifting_step_2/Gifting_step_2.js';
import Gifting_step_3 from '../Gifting_step_3/Gifting_step_3.js';
import Gifting_step_4 from '../Gifting_step_4/Gifting_step_4.js';
import Header_for_multistep from '../../../layout_components/Header_for_multistep/Header_for_multistep';
import Overlay_popup from '../../../general_components/Overlay_popup/Overlay_popup';

import GGApi from '../../../../utils/GGApi.js';

import dates from '../../../../utils/dates.js';
import validations from '../../../../utils/validations.js';
import Gifting_thank_you from '../Gifting_thank_you/Gifting_thank_you';
import Payment_methods from '../../../Payment_methods/Payment_methods';
import GG_loader from '../../../general_components/GG_loader/GG_loader';
import config from '../../../../utils/config';
import gg_fees from '../../../../utils/gg_pricing.js';
import GG_confetti from '../../../general_components/GG_confetti/GG_confetti';
import gg_icon from '../../../../images/logos/gg_logo_icon.png';

import amplitude from 'amplitude-js';
amplitude.getInstance().init(config.amp_key);



let guest_payment_recaptcha = React.createRef();

class Main_gift_flow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tokenIsValid: true,
            guest: '',

            loading: false,

            // navigations
            currentStep: 1,
            last_step: 0,
            open_payments: false,
            guest_overlay_open: false,

            // captcha
            captcha_token: '',

            // interactions with stripe
            payment_intent: '',
            new_payment_method: {},
            save_method: false,

            // payments
            payment_methods: [],
            selected_payment_method: '',
            accept_additional_charges: true,
            failed_count: 0,

            // button
            buttonText: 'Next',
            buttonLinks: false,
            button4Active: false,
            sent: false,

            // gift details
            from: '',
            sender_email: '',

            Gift_for: '',
            occasion: '',
            greeting: '',
            file: null,

            Gift_amount: '',
            other_gift_amount: '',
            schedule_delivery: 'later',
            Gift_delivery_date: '',
            Gift_delivery_shown_date: '',
            Gift_delivery_time: '',
            Gift_delivery_shown_time: '',
            timezone: {id: 1, title: "Eastern Time", tz: "America/New_York" },

            Send_to: '',
            channel: '',
            send_to_number: '',
            send_to_email: '',
            
            date: '',
            phone_support:'',

            // gifting page gift flow
            ref: null,
            receiver: null,
            receiver_number: null,

            // newsletter opt-in 
            opt_in: true
        }

         /* gift schema: 
                    Gift_for: ,
                    greeting: ,
                    Gift_amount: ,
                    Gift_delivery_date: ,
                    Send_to: ,
                    channel: ,
                    send_to_number: ,
                    send_to_email: ,
                    gift_status: ,
                    gift_id: ,
                    gift_from:
            */

        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleDollarInput = this.handleDollarInput.bind(this);
        this.handlePhoneChange  = this.handlePhoneChange.bind(this);
        this.handlePastePhone   = this.handlePastePhone.bind(this);
        this.updateChannel = this.updateChannel.bind(this);
        this.sendAnotherGift = this.sendAnotherGift.bind(this);
        this.handleOverlayBack = this.handleOverlayBack.bind(this);
        this.remember_gift = this.remember_gift.bind(this);
        this.handleDatePicker = this.handleDatePicker.bind(this);

        this.save_gift_for_oauth = this.save_gift_for_oauth.bind(this)

        this.handlePicChange = this.handlePicChange.bind(this);
        this.handleRemoveMedia = this.handleRemoveMedia.bind(this);

        this.removeSelectedPm = this.removeSelectedPm.bind(this);

        this.goToPayments = this.goToPayments.bind(this);
        this.updateCard = this.updateCard.bind(this);
        this.bankNext = this.bankNext.bind(this);
        this.handleDDSelection = this.handleDDSelection.bind(this);
        this.toggle = this.toggle.bind(this);

        
        this.pi_create = this.pi_create.bind(this);
        this.pi_update_recipient = this.pi_update_recipient.bind(this);
        this.pi_update_amount = this.pi_update_amount.bind(this);
        this.addFeesToIntent = this.addFeesToIntent.bind(this);
        this.removeFeesFromIntent = this.removeFeesFromIntent.bind(this);

        this.renderCaptcha = this.renderCaptcha.bind(this);
        this.handleRecaptcha = this.handleRecaptcha.bind(this);
        this.resetCaptcha = this.resetCaptcha.bind(this);

    }

    // on load, differentiate between logged in user, and guest 
    // also, check if this is coming from the landing page greeting section
    componentDidMount() {
        // check user vs guest:
        let user = JSON.parse(localStorage.getItem('user'));
        let validToken = '';
        let random_guest_signup = Math.floor( Math.random() * 2);
        // if there's a token, check that it's valid
        if (user) {
            GGApi.checkToken().then(response => {
                validToken = response;
                // if token is not valid, log out
                if (validToken === false) {
                    this.setState({tokenIsValid: false})

                } else if (validToken === true) {
                    // token is valid. set flow as signed in user
                    console.log('user validated. gifting as a user:');

                    //get the list of payment methods
                    GGApi.pm_get().then(list => {
                        if(list.length > 0) {
                            if(!list[0].disabled){
                                this.setState({selected_payment_method: list[0].items[0]})
                            }
                        };
                        this.setState({
                            payment_methods: list,
                            from: user.first_name, 
                            sender_email: user.email,
                            guest: false
                        });
                    })
                };
            });
        
            
        } else if (!user) {
            // if there is no token, continue as a guest
            console.log('going with a guest gifting flow');
            // A/B TEST
            this.setState({guest: true, payment_methods: [], guest_overlay_open: random_guest_signup === 0 ? true : false});
            amplitude.getInstance().setUserId(null);
        };

        // grab gift info if it exists
        if(this.props.greeting) {
            this.setState({greeting: this.props.greeting})
        } 
        if(this.props.from) {
            this.setState({
                from: this.props.from
            });
        }
        if(this.props.Gift_for){
            this.setState({
                Gift_for: this.props.Gift_for
            });
        }
        if(this.props.file){
            this.setState({
                file: this.props.file
            });
        }
        // check if there is a saved gift in process
        if(this.props.gift){
            this.setState({
                from: this.props.gift.from,
                sender_email: this.props.gift.sender_email,

                Gift_for: this.props.gift.Gift_for,
                greeting: this.props.gift.greeting,
                file: this.props.gift.file,

                Gift_amount: this.props.gift.Gift_amount,
                other_gift_amount: this.props.gift.other_gift_amount,
                Gift_delivery_date: this.props.gift.Gift_delivery_date,
                Gift_delivery_shown_date: this.props.gift.Gift_delivery_shown_date,
                Gift_delivery_time: this.props.gift.Gift_delivery_time,
                Gift_delivery_shown_time: this.props.gift.Gift_delivery_time,
                schedule_delivery: this.props.gift.schedule_delivery,

                Send_to: this.props.gift.Send_to,
                channel: this.props.gift.channel,
                send_to_number: this.props.gift.send_to_number,
                send_to_email: this.props.gift.send_to_email,

                date: this.props.gift.date,
                phone_support: this.props.gift.phone_support
            })
        }
        // if all greeting items were collected, skip to second page
        if(this.props.greeting && this.props.from && this.props.Gift_for && this.props.file){
            this.setState({
                Gift_for:       this.props.Gift_for,
                greeting:       this.props.greeting,
                from:           this.props.from,
                file:           this.props.file,
                currentStep:    2
            });
        }

        // grab info from url query params
        const urlParams = new URLSearchParams(window.location.search);
        if(urlParams.get('Gift_for'))        {this.setState({Gift_for:       urlParams.get('Gift_for')})};
        if(urlParams.get('Send_to'))         {this.setState({Send_to:        urlParams.get('Send_to')})};
        if(urlParams.get('channel'))         {this.setState({channel:        urlParams.get('channel')})};
        if(urlParams.get('send_to_email'))   {this.setState({send_to_email:  urlParams.get('send_to_email')})};     
        if(urlParams.get('send_to_number'))  {this.setState({send_to_number: urlParams.get('send_to_number')})};     
        
        // if ref is there, then we have an active receiver and this is the gifting page flow.  
        if(urlParams.get('ref') && urlParams.get('Send_to') && urlParams.get('d')){
            this.setState({receiver: urlParams.get('Send_to'), receiver_number: urlParams.get('d'), ref: urlParams.get('ref')});
        };


        // return from plaid OAuth
        // check if returned from OAuth - there is props plaid token info, and localstorage flow type
        if(this.props.plaid_link_token && this.props.plaid_session) {
            console.log('Continue from existing oauth...')
            
            // get the gift
            let gift = JSON.parse(localStorage.getItem('gift'));
            
            console.log('log the type of greeting file data: ', gift.file)
            console.log(typeof gift.file)
            console.log(typeof gift.file === 'string')

            this.setState({
                from: gift.from,
                sender_email: gift.sender_email,

                Gift_for: gift.Gift_for,
                greeting: gift.greeting,
                file: gift.file,

                Gift_amount: gift.Gift_amount,
                other_gift_amount: gift.other_gift_amount,
                Gift_delivery_date: gift.Gift_delivery_date,
                Gift_delivery_shown_date: gift.Gift_delivery_shown_date,
                Gift_delivery_time: gift.Gift_delivery_time,
                schedule_delivery: gift.schedule_delivery,

                Send_to: gift.Send_to,
                channel: gift.channel,
                send_to_number: gift.send_to_number,
                send_to_email: gift.send_to_email,

                date: gift.date,
                phone_support: gift.phone_support,

                payment_intent: gift.payment_intent
            })
            // set the right step in the flow - step 0, payments open

            this.setState({
                open_payments: true,
                currentStep: 0,
                last_step: 4,
                buttonText: 'Confirm & Send', 
                buttonLinks: true
            })
        }


        // amplitude
        if( urlParams.get('Gift_for') || urlParams.get('Send_to') || 
            urlParams.get('channel') || urlParams.get('send_to_email') || 
            urlParams.get('send_to_number') || urlParams.get('ref') || urlParams.get('d') ){
            amplitude.getInstance().logEvent(`gifting: start gift`, {"source": 'invited', "signup-popup": random_guest_signup === 0 ? "Open" : "Close", "user-gifting": user ? "true" : "false" });
        } else {
            amplitude.getInstance().logEvent(`gifting: start gift`, {"source": 'organic', "signup-popup": random_guest_signup === 0 ? "Open" : "Close", "user-gifting": user ? "true" : "false"});
        }
        
    }

    // Navigate the flow
    handleButtonClick() {

        // scroll to top of page
        window.scrollTo(0, 0);

        // check the token at every screen change for registered guests. 
        if(!this.state.guest){
            GGApi.checkToken().then(response => {
                let validToken = response;
                if (validToken === false) {
                    window.location.href = '/';
                    alert('You have been logged out due to inactivity. Please log back in to continue using the Greatest Gift website.');
                };
            });
        }

        let step = this.state.currentStep; 
        if (step === 1) {
            // amplitude
            amplitude.getInstance().logEvent('gifting: submit greeting', {'step': step});
            step += 1;
            this.setState({currentStep: step});

        } else if (step === 2) {
            
            // validate gift amount between 5 and 5000 dollars
            let gift_amount = Number(this.state.Gift_amount.slice(1));
            if (isNaN( gift_amount)) {
                if(this.state.guest) {
                    alert('Please input a valid gift amount, between $5 - $500. For a larger gift amount, please create a Greatest Gift account at app.GreatestGiftApp.com/new_account') 
                } else {
                    alert('Please input a valid gift amount, between $5 - $5,000') ;
                }
                // validate limits for guest
            } else if(this.state.guest && (gift_amount < 5 || gift_amount > 500) ){
                alert('Please input a valid gift amount, between $5 - $500. For a larger gift amount, please create a Greatest Gift account at app.GreatestGiftApp.com/new_account');
            } else if(!this.state.guest && (gift_amount < 5 || gift_amount > 5000) ){
                alert('Please input a valid gift amount, between $5 - $5,000');
            // validate delivery date
            } else { 
                if ((this.state.schedule_delivery === 'later' && !(this.state.Gift_delivery_date.length === 10)) 
                    || (this.state.schedule_delivery === 'later' && !dates.validateDate(this.state.Gift_delivery_date))) {
                    alert('Please input a valid delivery date in the following format:\nMM/DD/YYYY');
                } else if (this.state.schedule_delivery === 'later' && !dates.checkOneYear(this.state.Gift_delivery_date)) {
                    alert('Please enter a delivery date within the following year');
                } else {
                    // validate time is chosen, and if today time is not in the past
                    if(this.state.schedule_delivery === 'later' && !this.state.Gift_delivery_time){
                        alert('Please select a gift delivery time');
                    } else if (this.state.schedule_delivery === 'later' && this.state.Gift_delivery_time && 
                        this.state.Gift_delivery_date === dates.today() &&
                        dates.militarizeTime(this.state.Gift_delivery_time.title) < dates.current_time()
                    ) {
                        alert('Gift delivery time cannot be in the past');
                    } else {
                        // validations complete.

                        // update payment intent here (if needed):
                            // if there is a change to the gift amount - update the payment intent
                            if(this.state.payment_intent) {
                                this.pi_update_amount();
                            }

                        // finish and move to next step
                            amplitude.getInstance().logEvent('gifting: submit gift details', {'step': step});
                            step += 1;
                            this.setState({currentStep: step});
                    }
                }
            };
        } else if (step === 3) {
            
            // if gifting page flow - check that ref is present, and skip these validations
            if(this.state.ref){
                // if it's a guest - check that there's a sender email
                if (this.state.guest && !validations.validateEmail(this.state.sender_email)) {
                    alert('Please enter a valid sender email address');
                }  else {
                    // create a payment intent
                    if(!this.state.payment_intent){  
                        this.pi_create();
                    }

                    // amplitude
                    amplitude.getInstance().logEvent('gifting: submit send-to', {'step': step});

                    // go to next page
                    step += 1;
                    this.setState(
                        {
                        currentStep: step, 
                        buttonText: 'Confirm & Send', 
                        buttonLinks: true
                        });
                }
            }

            // check that at least one of the radio buttons is selected
            if (!this.state.channel && !this.state.ref) {
                alert('Please select one of the gift delivery methods')
            
                // validate the phone number, if it is selected
            } else if (this.state.channel === 'text_message') {
                if(!validations.validatePhone(this.state.send_to_number)) {
                    alert('Please enter a valid 10-digit US phone number in the following format:\nXXX-XXX-XXXX');
                } else if (this.state.guest && !validations.validateEmail(this.state.sender_email)) {
                    alert('Please enter a valid sender email address');
                }  else {
                    
                        if(!this.state.payment_intent){  // create a payment_intent
                            this.pi_create();

                        } else {  // update the recipient in the payment intent
                            //this.pi_update_recipient();
                        }

                    // navigate to next page
                        // if there is no payment method, go to add a payment method
                        //if(!this.state.payment_methods.length) {
                            //this.goToPayments();
                        //} else {
                            //amplitude
                            amplitude.getInstance().logEvent('gifting: submit send-to', {'step': step});
                            step += 1;
                            this.setState(
                                {
                                currentStep: step, 
                                buttonText: 'Confirm & Send', 
                                buttonLinks: true
                                });
                        //}
                };
                // validate the email, if it is selected
            } else if (this.state.channel === 'email') {
                if(!validations.validateEmail(this.state.send_to_email)) {
                    alert('Please enter a valid email address');

                // if we're in the guest gifting flow, validate the sender email as a valid email
                } else if (this.state.guest && !validations.validateEmail(this.state.sender_email)) {
                    alert('Please enter a valid sender email address');
                } else {
                    
                    // create a payment_intent
                        if(!this.state.payment_intent){
                            this.pi_create();

                        } else {  // update the recipient in the payment intent
                            //this.pi_update_recipient();
                        }  

                    // navigate to next page
                        // if there is no payment method, go to add a payment method
                        //if(!this.state.payment_methods.length) {
                            //this.goToPayments();
                        //} else {

                            //amplitude
                            amplitude.getInstance().logEvent('gifting: submit send-to', {'step': step});
                            step += 1;
                            this.setState(
                                {
                                currentStep: step, 
                                buttonText: 'Confirm & Send', 
                                buttonLinks: true
                                });
                        //};
                };
            };
        
        } else if (step === 4) {
            console.log('Finished front end flow. Attempting to send gift...');
            // may need to add a captcha here! so no automatic sending occurs by bots
            if(!this.state.selected_payment_method) {
                alert('Please select a payment method');
                return;
            }
            /* send http post request - send the gift data to the server, and from there to the database */
            let gift = {
                    Gift_for:                       this.state.Gift_for,
                    greeting:                       this.state.greeting,
                    Gift_amount:                    this.state.Gift_amount.slice(1),
                    Gift_delivery_date:             this.state.schedule_delivery === 'later' ? dates.sqlizeDate(this.state.Gift_delivery_date) : '',
                    Gift_delivery_time:             this.state.schedule_delivery === 'later' ? dates.militarizeTime(this.state.Gift_delivery_time.title) : '',
                    schedule_delivery:              this.state.schedule_delivery,
                    Send_to:                        this.state.Send_to,
                    channel:                        this.state.channel,
                    send_to_number:                 this.state.send_to_number,
                    send_to_email:                  this.state.send_to_email.toLowerCase(),
                    gift_from:                      this.state.from,
                    sender_email:                   this.state.sender_email.toLowerCase(),
                    
                    accepted_charges:               this.state.accept_additional_charges
            };
            
            //////////////////////////////////////
            // if this is a registered GG user: 
            if (!this.state.guest) {
                
                // send gift with token validation:
                
                /*
                let channel_input = this.state.channel === 'email' ? this.state.send_to_email : this.state.send_to_number;
                GGApi.get_receiver_stripe_acc(this.state.channel, channel_input).then(response => {
                    let recipient = response.receiver_stripe_account;  
                */
               this.setState({loading: true});
               let centAmount = Number(this.state.Gift_amount.slice(1)) * 100;
               let recipient = null; 
                // check if selected payment is ACH or CC
                if(this.state.selected_payment_method.type === 'CC'){
                    ////
                    GGApi.pi_finalize(this.state.payment_intent, this.state.selected_payment_method, recipient, centAmount)
                    .then(jsonResponse => {
                        if(jsonResponse.limit_passed){
                            console.log(jsonResponse);
                            if(jsonResponse.limit_fail === 'weekly_limit'){
                                alert(`You have reached your weekly rolling gift limit. Please try a lower gift amount, or wait until ${ dates.jsDate(jsonResponse.next_available.slice(0,10))} when your limit will reset.\nRemaining weekly balance: $${jsonResponse.remaining_weekly}`)
                            } else if (jsonResponse.limit_fail === 'under_minimum') {
                                alert(`The gift amount you have selected does not meet our minimum gift amount requirements. Please try again with a different gift amount.`);
                            } else {
                                alert(`The gift amount you have selected exceeds your account's gift limit of $${jsonResponse.transaction_limit}. Please enter a lower gift amount, or contact our support team at Support@GreatestGiftApp.com for additional information.`);
                            }
                            this.setState({loading: false});
                            return;
                        };

                        if(!jsonResponse.client_secret){
                            alert('logged out. Please log in again.')
                            this.setState({tokenIsValid: false});
                        } else {
                                                
                            let payment_method = this.state.selected_payment_method.id;
                            
                            // check if user requested to save payment_method for next time, if so, save it
                            let future_usage = this.state.selected_payment_method.save_this_method ? 'on_session' : null;
                            let return_url = window.location.href.slice(0,-11)+'gifts';
                            
                            const {stripe, elements} = this.props;
    
                            if (!stripe || !elements) {
                                // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
                                console.log('stripe didn\'t load');
                                this.setState({loading: false});
                                return;
                            }
    
                            // confirm the payment intent!
                            stripe.confirmCardPayment(jsonResponse.client_secret, 
                                {
                                    payment_method:         payment_method,
                                    receipt_email:          this.state.sender_email,
                                    setup_future_usage:     future_usage,
                                    return_url:             return_url
                                }
                            ).then(response => {
                                if(response.error){ // if there is an error in processing the charge
                                    this.removeSelectedPm();
                                
                                    let failed_count = this.state.failed_count;
                                    failed_count += 1; 
                                    this.setState({loading: false, failed_count: failed_count});
                                    // send an alert to an admin?
                                    GGApi.gift_error(response, this.state.sender_email, failed_count);
                                    alert('Please try a different payment method or contact our team for additional support.');
                                    return;
                                }
                                // if requested - save the payment method for future use 
                                if(this.state.selected_payment_method.save_this_method ){
                                    let {pm_name, pm_last4, type, id, image} = this.state.selected_payment_method;
                                    GGApi.pm_create(id, type, pm_name, pm_last4, image);
                                };
                                //let used_pm = response.paymentIntent.payment_method;
                                console.log(response);
                                
                                GGApi.upload_media(this.state.file, 'greetings-media').then(response => {
                                    // handle media upload error
                                    let media;
                                    if(response.errors){
                                        console.log(response.errors[0].detail);
                                        alert(response.errors[0].detail + ' \nGift will be sent without the greeting media.')
                                    } else if(response.mediaUrl){
                                        media = response.mediaUrl;
                                    };
                                    let payment_intent = this.state.payment_intent;
                                    
                                    // send gift in gg_db. 
                                    GGApi.send_gift(gift, media, payment_intent, this.state.selected_payment_method, this.state.ref); 
                                }).then(() => {
                                    // forget the saved gift
                                    this.props.forget_gift();
                                    // open the thank you menu
                                    this.setState({loading: false, sent: true});
                                    // amplitude
                                    amplitude.getInstance().logEvent('gifting: confirm gift', {'gift amount': gift.Gift_amount});
                                });
                            })
                        }
                        
                    })
                    ////
                
                } else if (this.state.selected_payment_method.type === 'ACH'){
                    // check the weekly and transaction limits
                    // create a charge on the backend, with centAmount, pi_id (for transfer group!), pm_id, fee_acceptance, receipt_email
                    
                    ////
                    GGApi.create_ach_charge(this.state.payment_intent, this.state.selected_payment_method.id, centAmount, this.state.accept_additional_charges, this.state.sender_email)
                    .then(response => {
                        // check if limits were passed
                        if(response.limit_passed){
                            if(response.limit_fail === 'weekly_limit'){
                                alert(`You have reached your weekly rolling gift limit. Please try a lower gift amount, or wait until ${ dates.jsDate(response.next_available.slice(0,10))} when your limit will reset.\nRemaining weekly balance: $${response.remaining_weekly}`)
                            } else if (response.limit_fail === 'under_minimum') {
                                alert(`The gift amount you have selected does not meet our minimum gift amount requirements. Please try again with a different gift amount.`);
                            } else {
                                alert(`The gift amount you have selected exceeds your account's gift limit of $${response.transaction_limit}. Please enter a lower gift amount, or contact our support team at Support@GreatestGiftApp.com for additional information.`);
                            }
                            this.setState({loading: false});
                            return;
                        };
                        // if other error, alert the user
                        if(!response.success){
                            alert(`We encountered a problem when trying to charge the selected bank account. Please try again, or select a different payment method.`);
                            this.setState({loading: false});
                        } else if (response.success){
                            // start the gg side gift - upload media, then send_gift
                            GGApi.upload_media(this.state.file, 'greetings-media').then(response => {
                                // handle media upload error
                                let media;
                                if(response.errors){
                                    console.log(response.errors[0].detail);
                                    alert(response.errors[0].detail + ' \nGift will be sent without the greeting media.')
                                } else if(response.mediaUrl){
                                    media = response.mediaUrl;
                                };
                                let payment_intent = this.state.payment_intent;
                                
                                // send gift in gg_db. 
                                GGApi.send_gift(gift, media, payment_intent, this.state.selected_payment_method, this.state.ref); 
                            }).then(() => {
                                // forget the saved gift
                                this.props.forget_gift();
                                // open the thank you menu
                                this.setState({loading: false, sent: true});
                                // amplitude
                                amplitude.getInstance().logEvent('gifting: confirm gift', {'gift amount': gift.Gift_amount});
                            });
                        }
                    }).catch(err => {
                        alert(`We encountered a problem when trying to charge the selected bank account. Please try again, or select a different payment method.`);
                        this.setState({loading: false});
                    })
                }
                
                //});

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////                
            // if this is a guest sending a gift:
            } else if (this.state.guest) {
                // no token validation on this method! 
                /*
                let channel_input = this.state.channel === 'email' ? this.state.send_to_email : this.state.send_to_number;
                GGApi.get_receiver_stripe_acc(this.state.channel, channel_input).then(response => {
                    let recipient = response.receiver_stripe_account;
                    */
                   
                    // for guests, check if the captcha was even filled out successfully!
                   
                    if(!this.state.captcha_token){
                        alert('Please fill out the captcha requirement.'); 
                        return;
                    };

                    let recipient = null;
                    let centAmount = Number(this.state.Gift_amount.slice(1)) * 100;
                    let captcha = this.state.captcha_token;
                    GGApi.pi_finalize_guest(this.state.payment_intent, this.state.selected_payment_method, recipient, centAmount, this.state.sender_email, captcha)
                    .then(jsonResponse => {    
                        if(jsonResponse.captcha === false){
                            alert('Encountered error with captcha validation. Please try again!')
                            this.resetCaptcha(guest_payment_recaptcha);
                            return;
                        };
                        if(jsonResponse.limit_passed){
                            if(jsonResponse.limit_fail === 'weekly_limit'){
                                alert(`You have reached your weekly rolling gift limit. If you would like to increase your gift limits, please register for an account at app.GreatestGiftApp.com/new_account.`)
                                this.resetCaptcha(guest_payment_recaptcha);
                                return;
                            } else if (jsonResponse.limit_fail === 'under_minimum') {
                                alert(`The gift amount you have selected does not meet our minimum gift amount requirements. Please try again with a different gift amount.`);
                                return;
                            } else {
                                alert(`The gift amount you have selected exceeds guest gift limit of $${jsonResponse.transaction_limit}. If you would like to select a larger gift amount, please register for an account at app.GreatestGiftApp.com/new_account.`);
                                return;
                            }
                        };
                        this.setState({loading: true});
                        
                        // check if user requested to save payment_method for next time, if so, save it
                        let payment_method = this.state.selected_payment_method.id;
                        let return_url = window.location.href.slice(0,-11);
                        
                        const {stripe, elements} = this.props;

                        if (!stripe || !elements) {
                            // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
                            console.log('stripe didn\'t load');
                            return;
                        }

                        // confirm the payment intent!
                        stripe.confirmCardPayment(jsonResponse.client_secret, 
                            {
                                payment_method:         payment_method,
                                receipt_email:          this.state.sender_email,
                                return_url:             return_url
                            }
                        ).then(response => {
                            if(response.error){
                                this.removeSelectedPm();
                                
                                let failed_count = this.state.failed_count;
                                failed_count += 1; 
                                this.setState({loading: false, failed_count: failed_count});
                                // alert an admin
                                GGApi.gift_error_guest(response, this.state.sender_email, failed_count);
                                alert('Please try a different payment method or contact our team for additional support.');
                                return;
                            }
                            let used_pm = response.paymentIntent.payment_method;
                            console.log(response);

                            GGApi.upload_media(this.state.file, 'greetings-media').then(response => {
                                // handle media upload error
                                let media;
                                if(response.errors){
                                    console.log(response.errors[0].detail);
                                    GGApi.notify_admin(`there was an error when logging a guest gift in the DB, trying to send without media:`, response.errors[0].detail)
                                    alert(response.errors[0].detail + ' \nGift will be sent without the greeting media.')
                                } else if(response.mediaUrl){
                                    media = response.mediaUrl;
                                };
                                let payment_intent = this.state.payment_intent;
                                // send gift in gg_db. 
                                GGApi.send_gift_as_guest(gift, media, payment_intent, used_pm, this.state.ref, this.state.opt_in); 
                            }).then(() => {
                                // forget the saved gift
                                this.props.forget_gift();
                                // open the thank you menu
                                this.setState({loading: false, sent: true});
                                // amplitude
                                amplitude.getInstance().logEvent('gifting: confirm gift', {'gift amount': gift.Gift_amount});
                                GGApi.admin_log_event('gift confirmed on front end');
                            }).catch(err => {
                                console.log('trying to send gift without media')
                                GGApi.notify_admin(`there was an error when logging a guest gift in the DB, trying to send without media: ${err}`, gift)
                                let payment_intent = this.state.payment_intent;
                                GGApi.send_gift_as_guest(gift, '', payment_intent, used_pm, this.state.ref, this.state.opt_in); 
                            });

                        }).catch(err => {
                            GGApi.notify_admin(`there was an error when logging a guest gift in the DB: ${err}`, gift)
                        })
                    })
                //})
            }
        };  
    }

    handleBackButton() {
        let step = this.state.currentStep;
        // amplitude
        amplitude.getInstance().logEvent('gifting: click back', {'step': step});
        if(this.state.open_payments) {
            this.setState({open_payments: false, currentStep: this.state.last_step})
        } else {
            if (step === 1) {
                this.remember_gift();
                //this.props.history.goBack()
                window.location.href = '/home';
    
            } else if (step === 2) {
                step -= 1;
                this.setState({currentStep: step});
    
            } else if (step === 3) {
                step -= 1;
                this.setState({currentStep: step});
                
            } else if (step === 4 && this.state.sent === false) {
                step -= 1;
                this.setState(
                    {   
                        currentStep: step,
                        buttonText: 'Next',
                        buttonLinks: false
                    });
            }
        }
    }

    goToPayments(){
        this.setState({
            open_payments: true,
            currentStep: 0,
            last_step: this.state.currentStep
        })
        // amplitude
        amplitude.getInstance().logEvent(`gifting: go to payment methods`);
    }

    // form event methods
    handleChange(event) {
        const { name, value} = event.target;
        this.setState({[name]: value});
        if(name === 'other_gift_amount'){
            this.setState({Gift_amount: value})
            // add the $ sign after blurring out of the box, if needed
            if (this.state[name][0] === '$' ) {
                return;
            } else if (this.state[name].length === 0) {
                return;
            } else {
                let $value = '$' + value;
                this.setState({Gift_amount: $value});
            };
        }
    }

    handlePicChange(event) {
        let file = event.target.files[0];
        // try to convert here to jpeg?
        if(!file){
            return;
        }
        if(file.size > 8388608) {
            alert('Please select a photo or video with a max size of 8MBs.');
            return;
        }
        this.setState(
            {file: event.target.files[0]}
        );
    }

    handleRemoveMedia(){
        this.setState({file: null});
    }
    
    handleDateChange(event) {
        const { name, value} = event.target;
        this.setState({[name]: value, date: value});

        if (event.target.value.length === 2 && this.state.date.length === 1) {
            let newValue = value + "/";
            this.setState({[name]: newValue});
        } else if (event.target.value.length === 5 && this.state.date.length === 4) {
            let newValue = value + "/";
            this.setState({[name]: newValue});
        };
        // reset the selected time when choosing today's date - avoid selection of past time in today
        if(event.target.value === dates.today()){
            this.setState({Gift_delivery_time: '', Gift_delivery_shown_time: ''})
        }
        
    }


    handleDatePicker(date, same_or_add_day) {
        // reset the selected time when choosing today's date - avoid selection of past time in today
        if(date === dates.today()){
            this.setState({Gift_delivery_time: '', Gift_delivery_shown_time: ''})
        }

        if(same_or_add_day === 1){
            this.setState({Gift_delivery_date: dates.date_plus_day(date), Gift_delivery_shown_date: date})
        } else {
            this.setState({Gift_delivery_date: date, date: date, Gift_delivery_shown_date: date});
        }
    }
    
    handlePhoneChange(event) {
        const { name, value} = event.target;
        this.setState({[name]: value, phone_support: value});

        if (event.target.value.length === 3 && this.state.phone_support.length === 2) {
            let newValue = value + "-";
            this.setState({[name]: newValue});
        } else if (event.target.value.length === 7 && this.state.phone_support.length === 6) {
            let newValue = value + "-";
            this.setState({[name]: newValue});
        };
        
    }

    handlePastePhone(input_name, formatted_number) {
        this.setState({[input_name]: formatted_number, phone_support: formatted_number});        
    }

    handleDollarInput(event) {
        const { name, value} = event.target;
        
        // add the $ sign after blurring out of the box, if needed
        if (this.state[name][0] === '$' ) {
            return;
        } else if (this.state[name].length === 0) {
            return;
        } else {
            let $value = '$' + value;
            this.setState({[name]: $value});
            if(name === 'other_gift_amount'){
                this.setState({Gift_amount: $value});
            }
        };
        // amplitude
        amplitude.getInstance().logEvent('gifting: select gift amount', {'gift amount': value})

    }

    updateChannel(input) {
        const name = 'channel';
        let value = '';
        if (input === 'channel_email') {
            value = 'email';
        } else if (input === 'channel_text') {
            value = 'text_message';
        };
        
        this.setState({[name]: value});
    }

    // payment section
    
    updateCard(payment_method, save_method){
        // save_method for future use is true or false object.
        
        let pm_logo = '';
        switch(payment_method.card.brand){
            case 'visa':
                pm_logo = 'https://gg-app-images.s3.us-east-2.amazonaws.com/linked_account_logos/logo_CC_visa.png';
                break;
            case 'amex':                
                pm_logo = 'https://gg-app-images.s3.us-east-2.amazonaws.com/linked_account_logos/logo_CC_AmEx.png';
                break;
            case 'diners':
                pm_logo = 'https://gg-app-images.s3.us-east-2.amazonaws.com/linked_account_logos/logo_CC_diners.png';
                break;
            case 'discover':
                pm_logo = 'https://gg-app-images.s3.us-east-2.amazonaws.com/linked_account_logos/logo_CC_discover.png';
                break;
            case 'mastercard':
                pm_logo = 'https://gg-app-images.s3.us-east-2.amazonaws.com/linked_account_logos/logo_CC_mastercard.png';
                break;
            default: 
                pm_logo = 'https://gg-app-images.s3.us-east-2.amazonaws.com/linked_account_logos/logo_CC_generic_card.png';
                break;
        }
        
        // set up the new pm in correct format for ddc
        let new_payment_method = {
            id: payment_method.id,
            type: 'CC',
            main_text: payment_method.card.brand,
            sub_text: '•••• ' + payment_method.card.last4,
            image: pm_logo,
            
            // info to be used to save payment method
            save_this_method: save_method,
            pm_name: payment_method.card.brand, 
            pm_last4: payment_method.card.last4,
        }
        // update - add the GG credit card fees to total amount on the payment intent
        this.addFeesToIntent(new_payment_method, this.state.payment_intent);       
        
        // add the payment method to the ddc list:
        let ddc_list = this.state.payment_methods;
        function findCategory(listItem) {
            if(listItem.category_header === 'Credit Cards' && new_payment_method.type === 'CC') {
                return true;
            } else if (listItem.category_header === 'Bank Debits - ACH' && new_payment_method.type === 'ACH'){
                return true;
            } else {
                return false;
            }
        };
        let index = ddc_list.findIndex(findCategory);
        if(index < 0) {
            let new_index = ddc_list.push({
                id: new_payment_method.type === 'CC' ? 2 : 1,
                category_header: new_payment_method.type === 'CC' ? 'Credit Cards' : 'Bank Debits - ACH',
                category_subtext: new_payment_method.type === 'CC' ? 
                `${String((gg_fees.send_fees.cc_variable*100).toFixed(1))}% ${gg_fees.send_fees.cc_fixed === 0 ? '': '+'+gg_fees.send_fees.cc_fixed+'c ' }Convenience Fee`
                //: "1% Convenience Fee",
                :`${String((gg_fees.send_fees.ach_variable*100).toFixed(1))}% ${gg_fees.send_fees.ach_fixed === 0 ? '': '+'+gg_fees.send_fees.ach_fixed+'c ' }Convenience Fee`,
                items: [],
                color: new_payment_method.type === 'CC' ? 'gg_red': ''
            }) - 1;
            ddc_list[new_index].items.push(new_payment_method);
        } else {
            ddc_list[index].items.push(new_payment_method);
        };

        // move to next page, and select the new method as the selected method
        this.setState({
            new_payment_method: new_payment_method,
            selected_payment_method: new_payment_method,
            save_method: save_method,
            payment_methods: ddc_list,

            open_payments: false,
            currentStep: 4,
            accept_additional_charges: true,
            
            buttonText: 'Confirm & Send',
            buttonLinks: true
        });
    }

    bankNext(new_bank){
        // set up the new pm in correct format for ddc
        let new_pm = {
            id: new_bank.method_api_id,
            type: new_bank.method_type,
            main_text: new_bank.method_name,
            sub_text: '•••• '+new_bank.method_last4,
            image: new_bank.method_logo,
        }
        // add the payment method to the ddc list:
        let ddc_list = this.state.payment_methods;
        function findCategory(listItem) {
            if(listItem.category_header === 'Credit Cards' && new_pm.type === 'CC') {
                return true;
            } else if (listItem.category_header === 'Bank Debits - ACH' && new_pm.type === 'ACH'){
                return true;
            } else if (listItem.category_header === 'Bank Debits - ACH (pending verification)' && new_pm.type === 'ACH_unverified'){
                return true;
            } else {
                return false;
            }
        };
        let index = ddc_list.findIndex(findCategory);
        if(index < 0) {
            let new_index = ddc_list.push({
                id: new_pm.type === 'ACH' ? 1 : 3,
                category_header: new_pm.type === 'ACH' ? 'Bank Debits - ACH' : 'Bank Debits - ACH (pending verification)',
                category_subtext: new_pm.type === 'ACH' ? 
                `${String(gg_fees.send_fees.ach_variable*100)}% ${gg_fees.send_fees.ach_fixed === 0 ? '': '+'+gg_fees.send_fees.ach_fixed+'c ' }Convenience Fee`:
                //"1% Convenience Fee" : 
                "Verification Required",
                items: [],
                disabled: new_pm.type === 'ACH' ? false : true
            }) - 1;
            ddc_list[new_index].items.push(new_pm);
        } else {
            ddc_list[index].items.push(new_pm);
        };

        // move to next page, and select the new method as the selected method
        this.setState({
            //  new_payment_method: new_pm,
            //  selected_payment_method: new_pm,
            //  save_method: save_method,
            payment_methods: ddc_list,

            open_payments: false,
            currentStep: 4,
            accept_additional_charges: true,
            
            buttonText: 'Confirm & Send',
            buttonLinks: true
        });
        if(new_pm.type === 'ACH'){
            this.setState({
                new_payment_method: new_pm,
                selected_payment_method: new_pm,
                payment_methods: ddc_list
            }); 
        }

    }    

    removeSelectedPm(){
        // remove selected pm from the ddc list
        let pm = this.state.selected_payment_method;

        function findCategory(listItem) {
            if(listItem.category_header === 'Credit Cards' && pm.type === 'CC') {
                return true;
            } else if (listItem.category_header === 'Bank Debits - ACH' && pm.type === 'ACH'){
                return true;
            } else {
                return false;
            }
        };

        function findPm(listPm) {
            if(listPm.id === pm.id) {
                return true;
            } else {
                return false;
            }
        };

        let ddc_list = this.state.payment_methods;
        let index = ddc_list.findIndex(findCategory);
        let pm_list = ddc_list[index].items;
        let pm_index = pm_list.findIndex(findPm);
        
        // splice the list
        ddc_list[index].items.splice(pm_index, 1);
        // set the new pm list
        this.setState({payment_methods: ddc_list, selected_payment_method: ''});
    }

    handleDDSelection(item, dd_name) {
        console.log('New pm selected');
        if (dd_name === 'payment_method') {
            // update the payment intent with new pm
            if(this.state.accept_additional_charges){
                this.addFeesToIntent(item, this.state.payment_intent);
            }
            // set the newly selected payment method
            this.setState(
                {   selected_payment_method: item, 
                    button4Active: true});
        }
    }

    toggle(){
        // if additional, show in the convenience fees. If false, make the convenience fees 0!
        // update the payment_intent to reflect the convenience fees, or not.
        if(this.state.selected_payment_method){   
            if(this.state.accept_additional_charges) {
                this.removeFeesFromIntent(this.state.selected_payment_method);        
            } else if (!this.state.accept_additional_charges) {
                this.addFeesToIntent(this.state.selected_payment_method, this.state.payment_intent)        
            }
        }
        this.setState({accept_additional_charges: !this.state.accept_additional_charges})
        // amplitude
        amplitude.getInstance().logEvent(`gifting: toggle fees`);
    }


    //pi_management
    pi_create(){
        // create a separate charges & transfers pi
        let centAmount = Math.round(Number(this.state.Gift_amount.slice(1)) * 100);
        if(!this.state.guest){
            GGApi.pi_create_separate_registered(centAmount).then(jsonResponse => {
                // need to add application fees here
                if(this.state.selected_payment_method && this.state.accept_additional_charges) {
                    this.addFeesToIntent(this.state.selected_payment_method, jsonResponse.pi_id)
                };
                this.setState({payment_intent: jsonResponse.pi_id})
            });
        } else {
            GGApi.pi_create_separate_guest(centAmount).then(jsonResponse => {
                // need to add application fees here
                if(this.state.selected_payment_method && this.state.accept_additional_charges) {
                    this.addFeesToIntent(this.state.selected_payment_method, jsonResponse.pi_id)
                };
                this.setState({payment_intent: jsonResponse.pi_id})
            });
        }


        /*
        let channel_input = this.state.channel === 'email' ? this.state.send_to_email : this.state.send_to_number;
        let centAmount = Number(this.state.Gift_amount.slice(1)) * 100;

        console.log(`creating PI.`)
        // check if recipient is registered GG user
        GGApi.get_receiver_stripe_acc(this.state.channel, channel_input).then(response => {
            if(response.receiver_stripe_account) {
                if(!this.state.guest){
                    GGApi.pi_create_destination_registered(centAmount, response.receiver_stripe_account).then(jsonResponse => {
                        // need to add application fees here
                        if(this.state.selected_payment_method && this.state.accept_additional_charges) {
                            this.addFeesToIntent(this.state.selected_payment_method, jsonResponse.pi_id)
                        };
                        this.setState({payment_intent: jsonResponse.pi_id})
                    });
                } else {
                    GGApi.pi_create_destination_guest(centAmount, response.receiver_stripe_account).then(jsonResponse => {
                        // need to add application fees here
                        if(this.state.selected_payment_method && this.state.accept_additional_charges) {
                            this.addFeesToIntent(this.state.selected_payment_method, jsonResponse.pi_id)
                        };
                        this.setState({payment_intent: jsonResponse.pi_id})
                    });
                }
            } else {
                if(!this.state.guest){
                    GGApi.pi_create_separate_registered(centAmount).then(jsonResponse => {
                        // need to add application fees here
                        if(this.state.selected_payment_method && this.state.accept_additional_charges) {
                            this.addFeesToIntent(this.state.selected_payment_method, jsonResponse.pi_id)
                        };
                        this.setState({payment_intent: jsonResponse.pi_id})
                    });
                } else {
                    GGApi.pi_create_separate_guest(centAmount).then(jsonResponse => {
                        // need to add application fees here
                        if(this.state.selected_payment_method && this.state.accept_additional_charges) {
                            this.addFeesToIntent(this.state.selected_payment_method, jsonResponse.pi_id)
                        };
                        this.setState({payment_intent: jsonResponse.pi_id})
                    });
                }
                
            }
        }) 
        */

    }

    pi_update_recipient(){
        let channel_input = this.state.channel === 'email' ? this.state.send_to_email : this.state.send_to_number;
        let centAmount = Number(this.state.Gift_amount.slice(1)) * 100;

        console.log(`updating PI (recipient).`)
        // check if the new recipient is registered GG user
        GGApi.get_receiver_stripe_acc(this.state.channel, channel_input).then(response => {
            if(response.receiver_stripe_account) {
                if(!this.state.guest){
                    GGApi.pi_update_destination_registered(this.state.payment_intent, response.receiver_stripe_account).then(jsonResponse => {
                        this.setState({payment_intent: jsonResponse.pi_id})
                    });
                } else {
                    GGApi.pi_update_destination_guest(this.state.payment_intent, response.receiver_stripe_account).then(jsonResponse => {
                        this.setState({payment_intent: jsonResponse.pi_id})
                    });
                }
                
            } else {
                if(!this.state.guest){
                    // to avoid issues with transfer_data info updates, create and work with a new pi for separate charges & transfers
                    GGApi.pi_create_separate_registered(centAmount).then(jsonResponse => {
                        if(this.state.selected_payment_method && this.state.accept_additional_charges) {
                            this.addFeesToIntent(this.state.selected_payment_method, jsonResponse.pi_id)
                        };
                        this.setState({payment_intent: jsonResponse.pi_id})
                    });
                } else {
                    // to avoid issues with transfer_data info updates, create and work with a new pi for separate charges & transfers
                    GGApi.pi_create_separate_guest(centAmount).then(jsonResponse => {
                        if(this.state.selected_payment_method && this.state.accept_additional_charges) {
                            this.addFeesToIntent(this.state.selected_payment_method, jsonResponse.pi_id)
                        };
                        this.setState({payment_intent: jsonResponse.pi_id})
                    });
                }
                
            }
        }) 
    }

    pi_update_amount(){
        // translate the amount into amount in cents (stripe requires this)
        let centAmount = Math.round(Number(this.state.Gift_amount.slice(1)) * 100);
        console.log(`updating PI with ${centAmount} amount of dollars.`)
        GGApi.pi_update_amount(this.state.payment_intent, centAmount, this.state.Gift_amount).then(jsonResponse => {
            ////
            // need to add application fees here
            if(this.state.selected_payment_method && this.state.accept_additional_charges) {
                this.addFeesToIntent(this.state.selected_payment_method, jsonResponse.pi_id)
            };
            ////

            this.setState({payment_intent: jsonResponse.pi_id})
        });
    }

    addFeesToIntent(method, pi_id){
        let fee;
        let amount = Number(this.state.Gift_amount.slice(1)) * 100;
        if(method.type === 'CC') {
            //fee = amount * 0.035; // break even for 100 dollars at 3.3% or 0.033. 
            fee = ( amount * gg_fees.send_fees.cc_variable ) + gg_fees.send_fees.cc_fixed;
        } else if (method.type === 'ACH') {
            //fee = amount * 0.01;
            fee = ( amount * gg_fees.send_fees.ach_variable ) + gg_fees.send_fees.ach_fixed;
        };

        fee = Math.floor(fee);
        let new_total_amount = amount + fee;
        
        console.log(`adding ${fee} to total payment_intent amount. Total amount: ${new_total_amount}`);
        // call the GGApi to update the payment_intent amount
        GGApi.pi_update_amount(pi_id, new_total_amount, this.state.Gift_amount).then(jsonResponse => {
            this.setState({payment_intent: jsonResponse.pi_id})
        });
    }

    removeFeesFromIntent(){
        let centAmount = Number(this.state.Gift_amount.slice(1)) * 100;
        console.log(`removing fees from payment intent. Total amount: ${centAmount}`);
        GGApi.pi_update_amount(this.state.payment_intent, centAmount, this.state.Gift_amount).then(jsonResponse => {
            this.setState({payment_intent: jsonResponse.pi_id})
        });
    }

    // recaptcha

    renderCaptcha(){
        if(!this.state.guest || this.state.currentStep !== 4){
            return null;
        } else {
            return (
                <div className='recaptcha_container'>
                    <ReCAPTCHA
                    sitekey={config.recaptcha_siteKey}
                    onChange={this.handleRecaptcha}
                    ref={guest_payment_recaptcha}
                        />
                </div>
            )
        }
    }

    handleRecaptcha(value){
        this.setState({captcha_token: value})
    }

    resetCaptcha(ref){
        ref.current.reset();
        this.setState({captcha_token: ''});
    }

    // thank you, and options
    sendAnotherGift() {
        // token is last validated at gift send step 4, but just in case someone left the computer after that...
        // first, check for valid tokens
        let user = JSON.parse(localStorage.getItem('user'));
        let validToken = '';
        // if there's a token, check that it's valid
        if (user) {
            GGApi.checkToken().then(response => {
                validToken = response;
                // if token is not valid, log out
                if (validToken === false) {
                    this.setState({tokenIsValid: false})

                } else if (validToken === true) {
                    // token is valid. set flow as signed in user
                    console.log('user validated. gifting as a user:');

                    //get the list of payment methods
                    GGApi.pm_get().then(list => {
                        if(list.length > 0) {
                            this.setState({selected_payment_method: list[0].items[0]})
                        };
                        this.setState({
                            payment_methods: list,
                            from: user.first_name, 
                            guest: false
                        });
                    })
                };
            });
        
            // if there is no token, continue as a guest
        } else if (!user) {
            console.log('going with a guest gifting flow');
            this.setState({
                guest: true, 
                payment_methods: [], 
                selected_payment_method: ''
            });
        };

        // reset the gifting flow, but keep some of the sender info if it exists
        this.setState(
            {
            currentStep: 1,
            last_step: 0,
            buttonText: 'Next',
            sent: false,

            payment_intent: '',

            Gift_for: '',
            greeting: '',

            Gift_amount: '',
            Gift_delivery_date: '',
            Gift_delivery_shown_date: '',
            Gift_delivery_time: '',
            Gift_delivery_shown_time: '',
            schedule_delivery: 'later',

            Send_to: '',
            channel: '',
            send_to_number: '',
            send_to_email: '',

            date: '',
            phone_support:''
            });
    }

    handleOverlayBack() {
        window.location.href = '/home';
    }

    remember_gift(){
        let gift = {
            from: this.state.from,
            sender_email: this.state.sender_email,

            Gift_for: this.state.Gift_for,
            occasion: this.state.occasion,
            greeting: this.state.greeting,
            file: this.state.file,

            Gift_amount: this.state.Gift_amount,
            other_gift_amount: this.state.other_gift_amount,
            Gift_delivery_date: this.state.Gift_delivery_date,
            Gift_delivery_shown_date: this.state.Gift_delivery_shown_date,
            Gift_delivery_time: this.state.Gift_delivery_time,
            Gift_delivery_shown_time: this.state.Gift_delivery_shown_time,
            schedule_delivery: this.state.schedule_delivery,

            Send_to: this.state.Send_to,
            channel: this.state.channel,
            send_to_number: this.state.send_to_number,
            send_to_email: this.state.send_to_email,

            date: this.state.date,
            phone_support: this.state.phone_support
        };
        this.props.remember_gift(gift);
    }
    
    save_gift_for_oauth(){
        
        GGApi.upload_media(this.state.file, 'greetings-media').then(response => {
            // handle media upload error
            let media;
            if(response.errors){
                console.log(response.errors[0].detail);
                console.log('Error when uploading file. Gift will be sent without the greeting media.');
            } else if(response.mediaUrl){
                media = response.mediaUrl;
            };

            let gift = {
                from: this.state.from,
                sender_email: this.state.sender_email,
    
                Gift_for: this.state.Gift_for,
                occasion: this.state.occasion,
                greeting: this.state.greeting,
                file: media,
    
                Gift_amount: this.state.Gift_amount,
                other_gift_amount: this.state.other_gift_amount,
                Gift_delivery_date: this.state.Gift_delivery_date,
                Gift_delivery_shown_date: this.state.Gift_delivery_shown_date,
                Gift_delivery_time: this.state.Gift_delivery_time,
                Gift_delivery_shown_time: this.state.Gift_delivery_shown_time,
                schedule_delivery: this.state.schedule_delivery,
    
                Send_to: this.state.Send_to,
                channel: this.state.channel,
                send_to_number: this.state.send_to_number,
                send_to_email: this.state.send_to_email,
    
                date: this.state.date,
                phone_support: this.state.phone_support,

                payment_intent: this.state.payment_intent
            };
            
            localStorage.setItem("gift", JSON.stringify(gift));

        })        
    }

    // render
    render () {
        if (!this.state.tokenIsValid) {
            return (<Redirect to="/login" />);
        };
        if (this.state.loading){
            return(<GG_loader header_text={!this.state.open_payments ? 'Give A Gift' : 'Add Payment'}/> )
        }
        return (

        <div>
            <Helmet>
                <title>{`Give a Gift | Greatest Gift`}</title>
                <meta property="og:title" content={`Give a Gift | Greatest Gift`}/>
                <link rel="icon" href="/static/media/logo_var_2.3a7e8fdf.png" />
                <link rel="canonical" href="https://app.greatestgiftapp.com/give-a-gift"/>
                <meta name="description"
                    content='Set a young one on the right financial path. Send monetary gifts for children`s long term savings.' 
                />
                <meta property="og:description" 
                    content='Set a young one on the right financial path. Send monetary gifts for children`s long term savings.' 
                />
                <meta property="og:image" content="https://gg-app-images.s3.us-east-2.amazonaws.com/articles/greatest_gift_money_gifts.png"/>
            </Helmet>

            <Header_for_multistep 
                text={!this.state.open_payments ? 'Give A Gift' : 'Add Payment'}
                handleBackButton={() => this.handleBackButton()}
            />
            
            <main>
                
                {!this.state.open_payments ? 
                    <Tracker numOfSteps={4} 
                            currentStep={this.state.currentStep}  
                            tags={['Greeting', 'Gift Details', 'Receiver Details', 'Confirm & Pay']}
                            /> 
                : null}

                {/* guest sign up or log in or skip */}
                <Overlay_popup 
                    open            = {this.state.guest_overlay_open}
                    closer          = {true}
                    handleClose     = {() => {this.setState({guest_overlay_open: false}); amplitude.getInstance().logEvent('gifting popup: click x'); }}
                    overlay_title   = 'Sign Up for Lower Fees'
                    wide            = {true}
            
                    body_1          = 'Payments with banks are only available to registered Greatest Gift users.'
                    body_2          = 'Sign up to access minimal 1.0% fees, or continue as a guest and make payments with credit cards.'
                    //image           = {stripe_CC_badge}
                    button_1        = {{text: 'Sign Up',
                                        links: true,
                                        linkTo: `/new_account?source=give-a-gift&${this.props.location.search.slice(1)}`,
                                        handler: () => {amplitude.getInstance().logEvent('signup: click sign up', {'page': 'gifting'});}
                                        }}    
                    button_2        = {{text: 'Continue as Guest',
                                        links: false,
                                        handler: () => { this.setState({guest_overlay_open: false}); amplitude.getInstance().logEvent('gifting popup: click guest');}
                                        }}    
                    joker           = {<div>
                        <p className='small_text'><Link to='/' className='green_text' onClick={() => { this.props.remember_gift_link(this.props.location.search); amplitude.getInstance().logEvent('gifting popup: click log in')}}>Log in</Link> if you already have an account</p>
                    </div>}
                />

                <Gifting_step_1 currentStep={this.state.currentStep} 
                                handleChange={this.handleChange}
                                Gift_for={this.state.Gift_for}
                                greeting={this.state.greeting}
                                buttonText={this.state.buttonText}
                                handleButtonClick={this.handleButtonClick}
                                guest={this.state.guest}
                                from={this.state.from}
                                file={this.state.file}
                                handlePicChange={this.handlePicChange}
                                handleRemoveMedia={this.handleRemoveMedia} 
                                occasion={this.state.occasion}
                                select_greeting={(selected_greeting)=> this.setState({greeting: selected_greeting})}/>

                <Gifting_step_2 currentStep={this.state.currentStep} 
                                handleChange={this.handleChange}
                                handleDateChange={this.handleDateChange}
                                handleDatePicker={this.handleDatePicker}
                                handleDollarInput={this.handleDollarInput}
                                Gift_amount={this.state.Gift_amount}
                                
                                Gift_delivery_date={this.state.Gift_delivery_date}
                                Gift_delivery_shown_date={this.state.Gift_delivery_shown_date}
                                move_date_by_one={() => this.setState({Gift_delivery_date: dates.date_plus_day(this.state.Gift_delivery_shown_date)})}
                                set_dates_same={() => this.setState({Gift_delivery_date: this.state.Gift_delivery_shown_date})}
                                set_date_plus_one={(new_date) => new_date === dates.today() ? 
                                    this.setState({Gift_delivery_date: dates.date_plus_day(new_date), Gift_delivery_shown_date: new_date, Gift_delivery_time: '', Gift_delivery_shown_time: ''}) 
                                    : 
                                    this.setState({Gift_delivery_date: dates.date_plus_day(new_date), Gift_delivery_shown_date: new_date}) }
                                set_date={(new_date) => new_date === dates.today() ? 
                                    this.setState({Gift_delivery_date: new_date, Gift_delivery_shown_date: new_date, Gift_delivery_time: '', Gift_delivery_shown_time: ''}) 
                                    : 
                                    this.setState({Gift_delivery_date: new_date, Gift_delivery_shown_date: new_date}) }
                                
                                schedule_delivery={this.state.schedule_delivery}
                                handleRadioClick={(schedule_delivery) => this.setState({schedule_delivery: schedule_delivery})}
                                Gift_delivery_time={this.state.Gift_delivery_time}
                                Gift_delivery_shown_time={this.state.Gift_delivery_shown_time}
                                update_timezone={(selection) => this.setState({timezone: selection})}
                                handleHourSelection={(eastern_time, selection) => this.setState({Gift_delivery_time: eastern_time, Gift_delivery_shown_time: selection})}
                                date={this.state.date}
                                buttonText={this.state.buttonText}
                                handleButtonClick={this.handleButtonClick}
                                set_amount={(new_amount) => this.setState({Gift_amount: new_amount}) }
                                other_gift_amount={this.state.other_gift_amount}
                                />

                <Gifting_step_3 currentStep={this.state.currentStep} 
                                handleChange={this.handleChange}
                                handlePhoneChange={this.handlePhoneChange}
                                handlePastePhone={this.handlePastePhone}
                                Send_to={this.state.Send_to}
                                send_to_number={this.state.send_to_number}
                                send_to_email={this.state.send_to_email}
                                buttonText={this.state.buttonText}
                                handleButtonClick={this.handleButtonClick}
                                updateChannel={this.updateChannel}
                                guest={this.state.guest}
                                sender_email={this.state.sender_email}
                                channel={this.state.channel}
                                receiver_number={this.state.receiver_number}
                                receiver={this.state.receiver}
                                />

                <Gifting_step_4 currentStep={this.state.currentStep} 
                                Gift_for={this.state.Gift_for}
                                greeting={this.state.greeting}
                                Gift_amount={this.state.Gift_amount}
                                Gift_delivery_date={this.state.Gift_delivery_date}
                                Gift_delivery_shown_date={this.state.Gift_delivery_shown_date}
                                Gift_delivery_time={this.state.Gift_delivery_time}
                                Gift_delivery_shown_time={this.state.Gift_delivery_shown_time}
                                schedule_delivery={this.state.schedule_delivery}
                                timezone={this.state.timezone}
                                Send_to={this.state.Send_to}
                                send_to_number={this.state.send_to_number}
                                send_to_email={this.state.send_to_email}
                                buttonText={this.state.buttonText}
                                handleButtonClick={this.handleButtonClick}
                                goToPayments={this.goToPayments}
                                guest={this.state.guest}
                                from={this.state.from}
                                handleDDSelection={this.handleDDSelection}
                                button4Active={this.state.button4Active}
                                payment_methods={this.state.payment_methods}
                                selected_payment_method={this.state.selected_payment_method}
                                accept_additional_charges={this.state.accept_additional_charges}
                                toggle={this.toggle}
                                opt_in={this.state.opt_in}
                                opt_in_out={() => {this.setState({opt_in: !this.state.opt_in})}}
                                gift_media={this.state.file}
                                />

                {this.renderCaptcha()}
                
                { this.state.open_payments ?
                
                <Payment_methods elements={this.props.elements} 
                                stripe={this.props.stripe}
                                guest={this.state.guest}
                                open_payments={this.state.open_payments} 
                                updateCard={this.updateCard}
                                bankNext={this.bankNext}
                                remember_gift={this.remember_gift}

                                save_gift_for_oauth={this.save_gift_for_oauth}
                                plaid_link_token={this.props.plaid_link_token} 
                                plaid_session={this.props.plaid_session}
                                cleanupOauth={this.props.cleanupOauth}
                                remember_gift_link={() => {this.props.remember_gift_link(this.props.location.search)}}
                                />
                            :
                null
                }

                <div className='gifting_footer'>
                    <a href='https://www.greatestgiftapp.com' target='_blank' rel="noreferrer">
                        <img src={gg_icon} alt='gg icon'/>
                    </a>
                    <p className='tiny_text'>Greatest Gift Inc.</p>
                    <p className='small_text'>|</p>
                    <a href='/legal?active=privacy' target='_blank' className='tiny_text' rel="noreferrer">Privacy</a>
                    <a href='/legal' target='_blank' rel="noreferrer" className='tiny_text'>Terms</a>
                    <a href='https://www.greatestgiftapp.com/#faq' target='_blank' rel="noreferrer" className='tiny_text'>FAQ</a>
                </div>
            
            </main>
            {this.state.sent ? 
            <GG_confetti />
            : null}
            <Gifting_thank_you 
                sent={this.state.sent}
                guest={this.state.guest}
                param1={this.state.Send_to}
                param2={this.state.Gift_for}
                sendAnotherGift={this.sendAnotherGift}
                handleOverlayBack={this.handleOverlayBack}
                />

           <div className='buffer'></div>

        </div>
        );
    }
}

export default Main_gift_flow;