
import amplitude from 'amplitude-js';
const config = require('./config');
const authServices = require('./authServices');
const gg_fees = require('./gg_pricing');
let baseUrl = config.gg_api_baseurl;
amplitude.getInstance().init(config.amp_key);


const GGApi = {
    
    /* -----------------  login interactions  --------------------------------- */

    // attempt to log in and authenticate a user
    log_in(login_attempt) {
        try {
            console.log('trying to authenticate user');
            return fetch(`${baseUrl}/users/login` , {
                method: 'POST',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(login_attempt)
            
            }).then(response => {
                return response.json();
            }).then(jsonResponse => {
                
                return jsonResponse;
            });
        } catch(e) {
            console.log(e);
        };
    },

    // check if a token is valid
    // if valid - return true, and save a new token to the local storage
    // if not valid - return false, and remove the existing token from the local storage
    checkToken() {
        let headers = authServices.authHeaders();
        return fetch(`${baseUrl}/users/validateSession`, {
            headers: headers
        })
        .then(response => {
            return response.json();
        }).then (jsonResponse => {

            if (jsonResponse.newToken) {
                // token was valid, and a new token was sent as a refresh, following user activity on the app
                localStorage.setItem("user", JSON.stringify(jsonResponse.newToken));

            } else if (!jsonResponse.tokenIsValid) {
                // the token is not valid. remove it from the local storage, and redirect to login page
                localStorage.removeItem("user");

            };
            return jsonResponse.tokenIsValid;
        })
        
    },

    /* -----------------  2FA  --------------------------------- */

    // check if a token is valid
    // if valid - return true, and save a new token to the local storage
    // if not valid - return false, and remove the existing token from the local storage
    check2FAToken() {
        const data_for_2fa = JSON.parse(localStorage.getItem('data_for_2fa'));
        let token = data_for_2fa.token_2fa
        let headers = 
            {
            'x-access-token': token, 
            };
        return fetch(`${baseUrl}/users/validate2FA`, {
            headers: headers
        })
        .then(response => {
            return response.json();
        }).then (jsonResponse => {
            
            if (!jsonResponse.tokenIsValid) {
                // the token is not valid. remove it from the local storage, and redirect to login page
                localStorage.removeItem("data_for_2fa");
                window.location.href = 'login';
            };
            return jsonResponse.tokenIsValid;
        })
        
    },

    // send_otp_for_2fa
    send_otp_for_2fa(verification_method) {
        const data_for_2fa = JSON.parse(localStorage.getItem('data_for_2fa'));
        let token = data_for_2fa.token_2fa
        let headers = 
            {
            "Content-Type": "application/json",
            'x-access-token': token
            };
        
        return fetch(`${baseUrl}/users/validate2FA/send_otp` , {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(
                { verification_method: verification_method })
        }).then(response => {
            return response.json();
        })
    },

    // check the otp input
    check_otp_for_2fa(otp, remember_me){
        const data_for_2fa = JSON.parse(localStorage.getItem('data_for_2fa'));
        let token = data_for_2fa.token_2fa
        let headers = 
            {
            "Content-Type": "application/json",
            'x-access-token': token
            };
        
        return fetch(`${baseUrl}/users/validate2FA/check_otp` , {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(
                {  otp: otp, remember_me: remember_me })
        }).then(response => {
            return response.json();
        }).then(jsonResponse => {
            if(jsonResponse.accessToken){
                localStorage.setItem("user", JSON.stringify(jsonResponse));
                amplitude.getInstance().logEvent('login: successful 2fa login');
                amplitude.getInstance().setUserId(jsonResponse.userId);
            };
            return jsonResponse;
        })
    },

    /* -----------------  GG user creation & interactions --------------------------------- */
    
    // Get verification code
    verify_phone_number(number) {

        return fetch(`${baseUrl}/users/verify/create_phone` , {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({number: number})

        }).then(response => {
            return response.json();
        });
    },

    // verify verification code
    verify_check_number(number, password_input) {
        /*  verification = {
                phone_number: number,
                password: password_input
            } */
        try {
            return fetch(`${baseUrl}/users/verify/check_phone` , {
                method: 'POST',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({phone_number: number, password: password_input})
            
            }).then(response => {
                return response;
            });

        } catch(err) {
            console.log(err);
        };
    },

    // Create (or register) a new user account
    create_user(newUser) {

        return fetch(`${baseUrl}/users/create_account` , {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({user: newUser})
        
        }).then(response => {
            if(!(response.status === 201)) {
                alert('Something in the signup process went wrong. Please try again.');
                window.location.reload();
            } else {
                return response.json();
            };
        }).then(jsonResponse => {
            if(jsonResponse.accessToken){
                localStorage.setItem("user", JSON.stringify(jsonResponse));
                amplitude.getInstance().setUserId(jsonResponse.userId);
                return jsonResponse;
            } else if (jsonResponse.message === 'Something in the signup process went wrong. Please try again.') {
                alert(jsonResponse.message);
            };
        });
        
    },

    uniqueEmail(email) {
        return fetch(`${baseUrl}/users/checkUnique1/${email}`)
        .then(response => {
            return response.json();
        }).then(jsonResponse => {
            return jsonResponse.unique; 
        });
    },

    uniquePhone(phone) {
        return fetch(`${baseUrl}/users/checkUnique2/${phone}`)
        .then(response => {
            return response.json();
        }).then(jsonResponse => {
            return jsonResponse.unique; 
        });
    },

    uniqueUser(username) {
        return fetch(`${baseUrl}/users/checkUnique3/${username}`)
        .then(response => {
            return response.json();
        }).then(jsonResponse => {
            return jsonResponse.unique; 
        });
    },

    // get the user's account details
    account_details(){
        let headers = authServices.authHeaders();
        return fetch(`${baseUrl}/users/account_details`, {
            headers: headers
        })
        .then(response => {
            return response.json();

        }).then (jsonResponse => {
            if (jsonResponse) {
                return jsonResponse;
            } else { 
                return null;
            };
        });
    },

    // get - resend email verification
    resend_email_verification(){
        let headers = authServices.authHeaders();
        return fetch(`${baseUrl}/users/verify/email/resend`, {
            headers: headers
        })
        .then(response => {
            return response.json();

        }).then (jsonResponse => {
            if (jsonResponse) {
                return jsonResponse;
            } else { 
                return null;
            };
        });
    },

    /* -----------------  settings --------------------------------- */

    // post - create a secondary email
    add_secondary_email(secondary_email) {
        const headers = 
            {
            'x-access-token': authServices.authHeaders()['x-access-token'], 
            "Content-Type": "application/json"
            };
        
        return fetch(`${baseUrl}/users/verify/email/create_secondary`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                secondary_email: secondary_email
            })
    
        }).then(response => {
            return response.json();
        });
    },


    //get a list of all verified secondary emails
    get_secondary_emails() {
        let headers = authServices.authHeaders();
        return fetch(`${baseUrl}/users/secondary_emails`, {
            headers: headers
        })
        .then(response => {
            return response.json();

        }).then (jsonResponse => {
            if (jsonResponse.email_list) {
                return jsonResponse.email_list;
            } else { 
                return [];
            };
        });
        
    },

    // post an email to get a reset password email
    reset_password_email(email) {
        try {
            return fetch(`${baseUrl}/users/password/reset_email` , {
                method: 'POST',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({email: email})
            
            }).then(response => {
                return response.json();
            }).then(jsonResponse => {
                return jsonResponse;
            });
        } catch(e) {
            console.log(e);
        };
    },


    checkPwdResetToken(token) {
        return fetch(`${baseUrl}/users/password/check_token`, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({token: token})
        })
        .then(response => {
            return response.json();
        }).then (jsonResponse => {
            return jsonResponse.tokenIsValid;
        })
        
    },

    create_new_password(token, new_password){
        return fetch(`${baseUrl}/users/password/reset_password_from_email`, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({token: token, password: new_password})
        })
        .then(response => {
            return response.json();
        })
    },

    create_new_password_from_login(new_password){
        return fetch(`${baseUrl}/users/password/reset_password_from_login`, {
            method: 'POST',
            headers: 
                {   
                    "Content-Type": "application/json",
                    'x-access-token': authServices.authHeaders()['x-access-token']  
                },
            body: JSON.stringify({password: new_password})
        })
        .then(response => {
            return response.json();
        })
    },

    //get a list of the notifications status
    getNotificationSettings() {
        let headers = authServices.authHeaders();
        return fetch(`${baseUrl}/users/notifications`, {
            headers: headers
        })
        .then(response => {
            return response.json();

        }).then (jsonResponse => {
            console.log(jsonResponse)
            if (jsonResponse.notifications) {
                return jsonResponse.notifications[0];
            } else { 
                return {
                    redeemGiftReminders: false,
                    birthdayOccasionReminders: false,
                    productGuides: false,
                    ageBasedTips: false,
                    personalizedContent: false,
                    partnerOffers: false,
                    announcements: false,
                };
            };
        });
        
    },

    updateNotificationSetting(setting, status){
            const headers = 
            {
            'x-access-token': authServices.authHeaders()['x-access-token'], 
            "Content-Type": "application/json"
            };
        console.log('updating notification')
        return fetch(`${baseUrl}/users/notifications`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                setting: setting,
                status: status
            })

        }).then(response => {
            return response.json();
        });
    },


    /* -----------------  send gifts and gifting history  --------------------------------- */

    // post a new gift / give a new gift
    send_gift(gift, media, pi_id, pm, ref) {
        gift.send_to_email = gift.send_to_email.toLowerCase();
        const user = JSON.parse(localStorage.getItem('user'));
        const headers = 
            {
            'x-access-token': authServices.authHeaders()['x-access-token'], 
            "Content-Type": "application/json"
            };
        
        return fetch(`${baseUrl}/sent_gifts/` , {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(
                {   gift: gift, 
                    from: gift.gift_from ? gift.gift_from : user.first_name + ' ' + user.last_name, 
                    mediaUrl: media,
                    pi_id: pi_id,
                    pm_id: pm.id,
                    pm_type: pm.type,
                    ref: ref ? ref : null
                })
        
        }).then(response => {
            return response.json();
        
        }).then(jsonResponse => {
            return jsonResponse;
        });
    },

    // post a new gift / give a new gift AS A GUEST
    send_gift_as_guest(gift, media, pi_id, pm_id, ref, opt_in) {
        gift.send_to_email = gift.send_to_email.toLowerCase();
        const headers = 
            { 
            "Content-Type": "application/json"
            };
        
        return fetch(`${baseUrl}/sent_gifts/guest` , {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(
                {   gift: gift,  
                    mediaUrl: media,
                    pi_id: pi_id,
                    pm_id: pm_id,
                    ref: ref ? ref : null,
                    opt_in: opt_in
                })
    
        }).then(response => {
            return response.json();
        
        }).then(jsonResponse => {
            return jsonResponse;
        });
    },

    gift_error(response, sender_email, failed_count){
        const headers = 
            {
            'x-access-token': authServices.authHeaders()['x-access-token'], 
            "Content-Type": "application/json"
            };
        
        return fetch(`${baseUrl}/stripe/payment_intent/error/registered` , {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(
                {   stripe_response: response,
                    sender_email: sender_email ? sender_email : null,
                    failed_count: failed_count
                })
        })
    },

    gift_error_guest(response, sender_email, failed_count){
        const headers = 
            {
            "Content-Type": "application/json"
            };
        
        return fetch(`${baseUrl}/stripe/payment_intent/error/guest` , {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(
                {   stripe_response: response,
                    sender_email: sender_email ? sender_email : null,
                    failed_count: failed_count
                })
        })
    },

    //get a list of all the gifts that a user sent
    sent_gifts() {
        let headers = authServices.authHeaders();
        return fetch(`${baseUrl}/sent_gifts/`, {
            headers: headers
        })
        .then(response => {
            return response.json();

        }).then (jsonResponse => {
            if (jsonResponse.sentGifts) {
                return jsonResponse.sentGifts;
            } else { 
                return [];
            };
        });
        
    },

    /* -----------------  media uploads  --------------------------------- */

    // upload an image / media to the aws s3 bucket
    async upload_media(file, destination) {
        if(!file) {
            return {mediaUrl: ''};
        } else if (file && typeof file === 'string'){
            return {mediaUrl: file};
        } else {
            const fd = new FormData();
            fd.append('destination', destination);
            fd.append('media', file, file.name);
            
            return fetch(`${baseUrl}/sent_gifts/media_upload` , {
                method: 'POST',
                
                body: fd
            
            }).then(response => {
                return response.json();
            
            }).then(jsonResponse => {
                return jsonResponse;
            }).catch(err => {
                alert('There was an error when trying to upload the image. Action will continue without the attached media.');
                return {mediaUrl: ''};
            });
        } 
    },

    /* -----------------  interact with received gifts  --------------------------------- */

    open_new_gift(gift_id) {
        return fetch(`${baseUrl}/received_gifts/open_new_gift/${gift_id}`)
        .then(response => {
            return response.json();
        }).then(jsonResponse => {
            if (jsonResponse.requestedGift) {
                return {requestedGift: jsonResponse.requestedGift, from: jsonResponse.from};
            } else {
                return {requestedGift: {}, from: '', access: 'No-access'};
            }
        });
    },

    // get all of a user's received gifts
    received_gifts() {
        let headers = authServices.authHeaders();
        return fetch(`${baseUrl}/received_gifts/all_received_gifts/`, {
            headers: headers
        })
        .then(response => {
            return response.json();

        }).then (jsonResponse => {
            return jsonResponse;
        });
        
    },

    // get the details of a recevied gift
    received_gift(gift_id) {
        let headers = authServices.authHeaders();
        return fetch(`${baseUrl}/received_gifts/received_gift/${gift_id}`, {
            headers: headers
        })
        .then(response => {
            return response.json();
        }).then(jsonResponse => {
            if (jsonResponse.requestedGift) {
                return {requestedGift: jsonResponse.requestedGift, from: jsonResponse.from};
            } else {
                return {requestedGift: {}, from: '', access: 'No-access'};
            }
        });
    },

    // get details of redeemed gift
    redeemed_gift(gift_id) {
        let headers = authServices.authHeaders();
        return fetch(`${baseUrl}/received_gifts/redeemed_gift/${gift_id}`, {
            headers: headers
        })
        .then(response => {
            return response.json();
        }).then(jsonResponse => {
            if (jsonResponse.applied_on) {
                return jsonResponse;
            } else {
                return {};
            }
        });
    },

    // get the names and id of the recipient accounts
    get_recipient_accounts() {
        let headers = authServices.authHeaders();
        return fetch(`${baseUrl}/received_gifts/recipients/`, {
            headers: headers
        })
        .then(response => {
            return response.json();
        });
    },

    // post redemption details, based on gift id, child account id, and fund id
    redeem_gift(redemption_details) {
        /* redemption_details schema = {
                gift_id,
                child_account_id, 
                fund_id
            } */
        const headers = 
            {
            'x-access-token': authServices.authHeaders()['x-access-token'], 
            "Content-Type": "application/json"
            };

        return fetch(`${baseUrl}/received_gifts/redeem_gift` , {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({redemption_details: redemption_details})
        
        }).then(response => {
            return response.json();
        
        })
    },

    // post thank you note
    send_thank_you(thank_you_note, thank_you_media, gift_id){
        let thank_you = {
            thank_you_note: thank_you_note,
            thank_you_media: thank_you_media
        }
        const headers = 
            {
            'x-access-token': authServices.authHeaders()['x-access-token'], 
            "Content-Type": "application/json"
            };

        return fetch(`${baseUrl}/received_gifts/send_thank_you` , {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(
                {   thank_you: thank_you,
                    gift_id: gift_id
                })
        
        }).then(response => {
            return response.json();
        })
    },

    /* -----------------  interact with gg plans and recipients  --------------------------------- */

    // add a new recipient account / plan
    add_account(accountInfo) {
        const headers = 
        {
        'x-access-token': authServices.authHeaders()['x-access-token'], 
        "Content-Type": "application/json"
        };

        return fetch(`${baseUrl}/plans/add_account/` , {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({accountInfo: accountInfo})
        
        }).then(response => {
            return response.json();
        
        }).then(jsonResponse => {
            console.log(jsonResponse);
            return jsonResponse;
        });
        
    },

    // add a new recipient account / plan with gifting page
    add_account_w_page(accountInfo, media) {
        const headers = 
        {
        'x-access-token': authServices.authHeaders()['x-access-token'], 
        "Content-Type": "application/json"
        };

        return fetch(`${baseUrl}/plans/add_account_w_page` , {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({accountInfo: accountInfo, media: media})
        
        }).then(response => {
            return response.json();
        
        }).then(jsonResponse => {
            console.log(jsonResponse);
            return jsonResponse;
        });
        
    },

    // get all of the plans associated with a user id
    get_plans() {
        let headers = authServices.authHeaders();
        return fetch(`${baseUrl}/plans/list/`, {
            headers: headers
        })
        .then(response => {
            return response.json();
        }).then (jsonResponse => {
            if (jsonResponse.plans) {
                return jsonResponse.plans;
            } else { 
                return [];
            };
        })
    },

    // get plan information by the plan id number
    get_plan_by_id(planId) {
        let headers = authServices.authHeaders();
        return fetch(`${baseUrl}/plans/plan/${planId}`, {
            headers: headers
        })
        .then(response => {
            return response.json();
        }).then(jsonResponse => {
            console.log(jsonResponse);
            if (jsonResponse.fetched_plan) {
                return {fetched_plan: jsonResponse.fetched_plan, other_registries: jsonResponse.other_registries};
            } else {
                return {access: 'No-access'};
            }
        });
    },

    unique_child_account(child_name){
        let headers = authServices.authHeaders();
        return fetch(`${baseUrl}/plans/add_account/check_unique/${child_name}`, {
            headers: headers
        })
        .then(response => {
            return response.json();
        }).then (jsonResponse => {
            return jsonResponse.unique; 
        })
    },


    // add a new recipient account / plan
    update_recipient_account(account_name, update_name, update_birthday) {
        const headers = 
        {
        'x-access-token': authServices.authHeaders()['x-access-token'], 
        "Content-Type": "application/json"
        };

        return fetch(`${baseUrl}/plans/update_account` , {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({account_name: account_name, update_name: update_name, update_birthday: update_birthday})
        
        }).then(response => {
            return response.json();
        
        }).then(jsonResponse => {
            console.log(jsonResponse);
            return jsonResponse;
        });
        
    },


    // post request for gifts
    share_request_for_gifts(captcha, recipient_name, asker_name, child, asker_email, recipient_email, media) {
        asker_email = asker_email.toLowerCase();
        recipient_email = recipient_email.toLowerCase();

        const headers = 
            { 
            "Content-Type": "application/json"
            };
        
        return fetch(`${baseUrl}/plans/share_request_for_gifts/` , {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(
                {   captcha: captcha,
                    recipient_name: recipient_name, 
                    asker_name: asker_name, 
                    child: child, 
                    asker_email: asker_email, 
                    recipient_email: recipient_email, 
                    media: media
                })
    
        }).then(response => {
            return response.json();
        
        }).then(jsonResponse => {
            return jsonResponse;
        });
    },

    /* -----------------  gifting pages  --------------------------------- */

    // get plan information by the plan id number
    get_gifting_page(slug) {
        let headers = authServices.authHeaders();
        return fetch(`${baseUrl}/plans/gifting_page/${slug}`, {
            headers: headers
        })

        .then(response => {
            
            return response.json();

        }).then(jsonResponse => {
            console.log(jsonResponse);
            if (jsonResponse.gifting_page) {
                return {
                    gifting_page: jsonResponse.gifting_page, 
                    sent_gifts: jsonResponse.sent_gifts, 
                    gifting_link: jsonResponse.gifting_link,
                    gifts_activity: jsonResponse.gifts_activity,
                    other_registries: jsonResponse.other_registries
                };
            } else {
                return {access: 'No-access'};
            }
        });
    },
    

    // post - update the gifting page
    update_gifting_page(child_account_id, page_active, welcome_message, media_url, goals, show_activity, display_other_registries, other_registries){
        const headers = 
            {
            'x-access-token': authServices.authHeaders()['x-access-token'], 
            "Content-Type": "application/json"
            };

        return fetch(`${baseUrl}/plans/update_gifting_page` , {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                child_account: child_account_id,
                page_active: page_active,
                welcome_message: welcome_message,
                media_url: media_url,
                goals: goals,
                show_activity: show_activity,
                display_other_registries: display_other_registries,
                other_registries: other_registries
            })
        
        }).then(response => {
            return response.json();
        
        })
    },

    /* -----------------  linked accounts & payments  --------------------------------- */
    
    get_recipient_funds(recipient_id){
        let headers = authServices.authHeaders();
        return fetch(`${baseUrl}/received_gifts/get_recipient_funds/${recipient_id}`, {
            headers: headers
        })
        .then(response => {
            return response.json();
        }).then(jsonResponse => {
            if (jsonResponse.linked_accounts) {
                // response may have a list, that contains items
                // each item has: fund_type, institution_name, fund_api_id, fund_last_4, fund_logo
                let linked_accounts = jsonResponse.linked_accounts;
                // possible fund types: 529 Plan, Investment Account, Savings Account, Checking Account, US Savings Bonds
                
                //create an array with all the categories
                let categories = [];
                let list = [];
                
                
                let checking_redeem_fee    =  `${String((gg_fees.redeem_fees.checking_variable   *100).toFixed(1))}% ${gg_fees.redeem_fees.checking_fixed    === 0 ? '': '+ '+gg_fees.redeem_fees.checking_fixed+'¢' }`;
                let other_redeem_fee       =  `${String((gg_fees.redeem_fees.other_variable      *100).toFixed(1))}% ${gg_fees.redeem_fees.other_fixed       === 0 ? '': '+ '+gg_fees.redeem_fees.other_fixed+'¢' }`;

                linked_accounts.forEach(element => { 
                    if(element.fund_type === '529 Plan') {
                        if(!categories.includes('529 Plan')){
                            list.push({
                                id: 1,
                                category_header: '529 Plans',
                                // category_subtext: other_redeem_fee + ' To Redeem',
                                category_subtext: gg_fees.redeem_fees.other_fixed === 0 && gg_fees.redeem_fees.other_variable === 0 ? 'No Fees' : other_redeem_fee + ' To Redeem',
                                items: []
                            });
                            categories.push(element.fund_type);
                        }
                    } else if(element.fund_type === 'Investment Account') {
                        if(!categories.includes('Investment Account')){
                            list.push({
                                id: 2,
                                category_header: 'Investment Accounts',
                                // category_subtext: other_redeem_fee + ' To Redeem',
                                category_subtext: gg_fees.redeem_fees.other_fixed === 0 && gg_fees.redeem_fees.other_variable === 0 ? 'No Fees' : other_redeem_fee + ' To Redeem',
                                items: []
                            });
                            categories.push(element.fund_type);
                        }
                    /*
                    } else if(element.fund_type === 'US Savings Bonds') {
                        if(!categories.includes('US Savings Bonds')){
                            list.push({
                                id: 3,
                                category_header: 'US Savings Bonds',
                                // category_subtext: other_redeem_fee + ' To Redeem',
                                category_subtext: gg_fees.redeem_fees.other_fixed === 0 && gg_fees.redeem_fees.other_variable === 0 ? 'No Fees' : other_redeem_fee + ' To Redeem',
                                items: []
                            });
                            categories.push(element.fund_type);
                        }
                        */
                    } else if(element.fund_type === 'Savings Account') {
                        if(!categories.includes('Savings Account')){
                            list.push({
                                //id: 4,
                                id: 3,
                                category_header: 'Savings Accounts',
                                // category_subtext: other_redeem_fee + ' To Redeem',
                                category_subtext: gg_fees.redeem_fees.other_fixed === 0 && gg_fees.redeem_fees.other_variable === 0 ? 'No Fees' : other_redeem_fee + ' To Redeem',
                                items: []
                            });
                            categories.push(element.fund_type);
                        }
                    } else if(element.fund_type === 'Checking Account') {
                        if(!categories.includes('Checking Account')){
                            list.push({
                                //id: 5,
                                id: 4,
                                category_header: 'Checking Accounts',
                                category_subtext: checking_redeem_fee + ' To Redeem',
                                color: 'gg_red',
                                items: []
                            });
                            categories.push(element.fund_type);
                        }
                    };

                    // add the item to the item list in the relevant category
                    function checkCategoryExists(listItem) {
                        //return (listItem.category_header === element.fund_type + (listItem.category_header === 'US Savings Bonds' ? '' : 's'));
                        return (listItem.category_header === element.fund_type + 's');
                    }
                    let index = list.findIndex(checkCategoryExists);
                    let newItem = {   
                        image: element.fund_logo,
                        main_text: element.institution_name,
                        sub_text: '•••• '+element.fund_last_4,
                        id: element.fund_api_id,
                        fund_type: element.fund_type
                        };

                    list[index].items.push(newItem);
                });
                // sort the list by the category id number
                let sorted_list = list.sort((a,b) => a.id - b.id);
                return sorted_list;
            } else {
                let emptyList = [];
                return emptyList;
            }  
        })
    },

    /* -----------------  Stripe Connect Account integrations  --------------------------------- */
    
    // create a stripe account and associate it with the GG user
    create_stripe_account() {
        const headers = 
            {
            'x-access-token': authServices.authHeaders()['x-access-token'], 
            "Content-Type": "application/json"
            };
        
        return fetch(`${baseUrl}/stripe/create_stripe_account`, {
            method: 'POST',
            headers: headers
    
        }).then(response => {
            console.log('received response');
            console.log(response);
        });
    },
    
    // update stripe account info with verification details
    update_stripe_account(digits, dob) {
        const headers = 
            {
            'x-access-token': authServices.authHeaders()['x-access-token'], 
            "Content-Type": "application/json"
            };
        
        return fetch(`${baseUrl}/stripe/update_personal_stripe`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                digits: digits, 
                dob: dob
            })
    
        }).then(response => {
            console.log('received response');
            console.log(response);
        });
    },

    // get request - check if user already linked any bank accounts or not
    check_first_link() {
        let headers = authServices.authHeaders();
        return fetch(`${baseUrl}/stripe/check_first_linked`, {
            headers: headers
        })
        .then(response => {
            return response.json();
        }).then (jsonResponse => {
            if (jsonResponse.first_link) {
                return jsonResponse.first_link;
            } else { 
                return false;
            };
        })
    },

    // send a stripe token to the GG server, and have it associated with a user
    create_bank(stripe_token, recipient, account_type, institution_name, logo, public_token, plaid_account_id, from_plaid) {
        const headers = 
            {
            'x-access-token': authServices.authHeaders()['x-access-token'], 
            "Content-Type": "application/json"
            };
        
        return fetch(`${baseUrl}/stripe/create_bank`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                token: stripe_token, 
                child_account: recipient, 
                account_type: account_type,
                institution_name: institution_name,
                logo: logo,
                from_plaid: from_plaid,
                public_token: public_token, 
                plaid_account_id: plaid_account_id,
            })
    
        }).then(response => {
            console.log('received response');
            console.log(response);
            return response.json();
        
        }).then(jsonResponse => {
            console.log(jsonResponse);
            return jsonResponse;
        });
    },

    /* -----------------  Stripe Customer integrations  --------------------------------- */

    // create a stripe account and associate it with the GG user
    create_stripe_customer() {
        const headers = 
            {
            'x-access-token': authServices.authHeaders()['x-access-token'], 
            "Content-Type": "application/json"
            };
        
        return fetch(`${baseUrl}/stripe/create_customer`, {
            method: 'POST',
            headers: headers
    
        }).then(response => {
            console.log('received response');
            console.log(response);
        });
    },

    // send a pm to the gg  db and save it to a customer
    pm_create(pm, pm_type, pm_name, pm_last4, logo) {
        const headers = 
            {
            'x-access-token': authServices.authHeaders()['x-access-token'], 
            "Content-Type": "application/json"
            };
        
        return fetch(`${baseUrl}/stripe/payment_method/cc`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                pm: pm, 
                pm_type: pm_type,
                pm_name: pm_name,
                pm_last4: pm_last4,
                logo: logo
                })
    
        }).then(response => {
            return response.json();
        
        });
    },

    // get list of payment methods
    pm_get(){
        let headers = authServices.authHeaders();
        return fetch(`${baseUrl}/stripe/payment_method`, {
            headers: headers
        })
        .then(response => {
            return response.json();
        }).then(jsonResponse => {
            if (jsonResponse.payment_methods) {
                // response may have a list, that contains items
                // each item has: method_type, method_api_id, method_logo, method_name, method_last4
                let payment_methods = jsonResponse.payment_methods;
                // possible payment method types: card, bank

                //create an array with all the categories
                let categories = [];
                
                let list = [];
                
                let ach_send_fee    =  `${String((gg_fees.send_fees.ach_variable*100).toFixed(1))}% ${gg_fees.send_fees.ach_fixed === 0 ? '': '+'+gg_fees.send_fees.ach_fixed+'¢' }`;
                let cc_send_fee     =  `${String((gg_fees.send_fees.cc_variable*100).toFixed(1))}% ${gg_fees.send_fees.cc_fixed === 0 ? '': '+'+gg_fees.send_fees.cc_fixed+'¢' }`;   

                payment_methods.forEach(element => { 
                    if(element.method_type === 'CC') {
                        if(!categories.includes('CC')){
                            list.push({
                                id: 2,
                                category_header: 'Credit Cards',
                                category_subtext: cc_send_fee + ' Convenience Fee',
                                items: [],
                                color: 'gg_red'
                            });
                            categories.push(element.method_type);
                        }
                    } else if(element.method_type === 'ACH' && element.verified === 1) {
                        if(!categories.includes('ACH')){
                            list.push({
                                id: 1,
                                category_header: 'Bank Debits - ACH',
                                category_subtext: ach_send_fee + " Convenience Fee",
                                items: []
                            });
                            categories.push(element.method_type);
                        }

                    } else if(element.method_type === 'ACH' && element.verified === 0) {
                        if(!categories.includes('ACH_unverified')){
                            list.push({
                                id: 3,
                                category_header: 'Bank Debits - ACH (pending verification)',
                                category_subtext: "Verification Required",
                                items: [],
                                disabled: true
                            });
                            categories.push('ACH_unverified');
                        }
                    };
                

                    // add the item to the item list in the relevant category
                    function checkCategoryExists(listItem) {
                        if(listItem.category_header === 'Credit Cards' && element.method_type === 'CC') {
                            return true;
                        } else if (listItem.category_header === 'Bank Debits - ACH' && element.method_type === 'ACH' && element.verified === 1){
                            return true;
                        } else if (listItem.category_header === 'Bank Debits - ACH (pending verification)' && element.method_type === 'ACH' && element.verified === 0){
                            return true;
                        } else {
                            return false;
                        }
                    }
                    let index = list.findIndex(checkCategoryExists);
                    let newItem = {   
                        id: element.method_api_id,
                        type: element.method_type,
                        main_text: element.method_name,
                        sub_text: '•••• '+element.method_last4,
                        image: element.method_logo
                        };

                    list[index].items.push(newItem);
                });
                // sort the list by the category id number
                let sorted_list = list.sort((a,b) => a.id - b.id);
                return sorted_list;
            } else {
                let emptyList = [];
                return emptyList;
            }  
        })
    },

    bank_create(tokenId, logo, from_plaid, public_token, plaid_account_id){
        const headers = 
            {
            'x-access-token': authServices.authHeaders()['x-access-token'], 
            "Content-Type": "application/json"
            };
        
        return fetch(`${baseUrl}/stripe/payment_method/bank`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                token: tokenId,
                logo: logo,
                
                from_plaid: from_plaid,

                public_token: public_token, 
                plaid_account_id: plaid_account_id
                })
    
        }).then(response => {
            return response.json();
        
        });
    },

    verify_manual_bank(deposit_1, deposit_2, bank_id){
        const headers = 
            {
            'x-access-token': authServices.authHeaders()['x-access-token'], 
            "Content-Type": "application/json"
            };
        
        return fetch(`${baseUrl}/stripe/payment_method/verify_bank_manual`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                deposit_1: deposit_1,
                deposit_2: deposit_2,
                bank_id: bank_id
                })

        }).then(response => {
            return response.json();
        });
    },

    /* -----------------  Plaid integrations  --------------------------------- */

    get_plaid_link(link_type){
        const headers = 
            {
            'x-access-token': authServices.authHeaders()['x-access-token'], 
            "Content-Type": "application/json"
            };
        
        return fetch(`${baseUrl}/stripe/plaid/create_link_token`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({link_type: link_type}) 

        }).then(response => {
                return response.json();
        });
    },

    track_fund_balance(public_token, fund_id){
        const headers = 
            {
            'x-access-token': authServices.authHeaders()['x-access-token'], 
            "Content-Type": "application/json"
            };
        
        return fetch(`${baseUrl}/stripe/plaid/track_fund_balance`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({public_token: public_token, fund_id: fund_id}) 

        }).then(response => {
                return response.json();
        });
    },

    /* -----------------  Stripe charges integrations  --------------------------------- */

    // create a stripe charge for a gift with ACH
    create_ach_charge(pi_id, payment_method_id, centAmount, accepted_fees, receipt_email) {
        const headers = 
            {
            'x-access-token': authServices.authHeaders()['x-access-token'], 
            "Content-Type": "application/json"
            };
        
        return fetch(`${baseUrl}/stripe/charges/ach`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                pi_id: pi_id,
                payment_method_id: payment_method_id,
                amount: centAmount,
                accepted_fees: accepted_fees,
                receipt_email: receipt_email
            })
    
        }).then(response => {
                return response.json();
                /* if limits are failed:
                    {   
                        limit_passed: true, 
                        limit_fail: 'weekly_limit' / 'transaction_limit'
                        next_available: next_available,
                        transaction_limit: limit in $ amount,
                        remaining_weekly: (weekly_limit - weekly_volume)/100
                    }
                */
            
        });
    },

    /* -----------------  Stripe PI integrations  --------------------------------- */


    // create a payment_intent - separate charge & transfer
    pi_create_separate_registered(centAmount) {
        const headers = 
            {
            'x-access-token': authServices.authHeaders()['x-access-token'], 
            "Content-Type": "application/json"
            };
        
        return fetch(`${baseUrl}/stripe/payment_intent/create_separate/registered`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({amount: centAmount})
    
        }).then(response => {
            return response.json();
        });
    },

    // update a payment_intent - new amount
    pi_update_amount(payment_intent, centAmount, orig_gift_amount) {
        const headers = 
            {
            "Content-Type": "application/json"
            };
        
        return fetch(`${baseUrl}/stripe/payment_intent/update_amount`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({pi_id: payment_intent, amount: centAmount, orig_gift_amount: orig_gift_amount})
    
        }).then(response => {
            return response.json();
        });
        
    },

    // finalize a payment_intent - before the confirmation
    pi_finalize(payment_intent, payment_method, recipient, centAmount) {
        let new_recipient = recipient ? recipient : null;
        const headers = 
            {
            'x-access-token': authServices.authHeaders()['x-access-token'], 
            "Content-Type": "application/json"
            };
        
        return fetch(`${baseUrl}/stripe/payment_intent/finalize`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                pi_id: payment_intent, 
                payment_method_id: payment_method.id,
                recipient: new_recipient,
                amount: centAmount
            })
    
        }).then(response => {
                return response.json();
                /* if limits are failed:
                    {   
                        limit_passed: true, 
                        limit_fail: 'weekly_limit' / 'transaction_limit' / 'under_minimum'
                        next_available: next_available,
                        transaction_limit: limit in $ amount,
                        remaining_weekly: (weekly_limit - weekly_volume)/100
                    }
                */
            
        });
    },

    /* -----------------  Stripe PI integrations (GUEST)  --------------------------------- */


    // create a payment_intent - separate charge & transfer
    pi_create_separate_guest(centAmount) {
        const headers = 
            {
            "Content-Type": "application/json"
            };
        
        return fetch(`${baseUrl}/stripe/payment_intent/create_separate/guest`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({amount: centAmount})
    
        }).then(response => {
            return response.json();
        });
    },

    // finalize a payment_intent for guests - before the confirmation
    pi_finalize_guest(payment_intent, payment_method, recipient, centAmount, sender_email, captcha) {
        const headers = 
            {
            "Content-Type": "application/json"
            };
        
        return fetch(`${baseUrl}/stripe/payment_intent/finalize_guest`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                pi_id: payment_intent, 
                payment_method_id: payment_method.id,
                recipient: recipient,
                amount: centAmount,
                guest: true,
                guest_sender_email: sender_email,
                captcha: captcha
            })
    
        }).then(response => {
            return response.json();
            /* if limits are failed:
                {   
                    limit_passed: true, 
                    limit_fail: 'weekly_limit' / 'transaction_limit'
                    next_available: next_available
                }
            */
           // if response has a captcha item - true / false
        });
    },

    /* -----------------  Stripe PI - Destination  --------------------------------- */


    // check if the send to details are for a registered user
    get_receiver_stripe_acc(channel, channel_input){
        const headers = 
            {
            "Content-Type": "application/json"
            };
        
        return fetch(`${baseUrl}/stripe/payment_intent/get_receiver_stripe_acc` , {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(
                {   channel: channel, 
                    channel_input: channel_input
                }
            )
        
        }).then(response => {
            return response.json();
        
        }).then(jsonResponse => {
            return jsonResponse;
            
        });
    },

    // create a payment_intent - destination charge
    pi_create_destination_registered(centAmount, recipient) {
        const headers = 
            {
            'x-access-token': authServices.authHeaders()['x-access-token'], 
            "Content-Type": "application/json"
            };
        
        return fetch(`${baseUrl}/stripe/payment_intent/create_destination/registered`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({amount: centAmount, recipient: recipient})
    
        }).then(response => {
            return response.json();
        });
    },

    // create a payment_intent - destination charge
    pi_create_destination_guest(centAmount, recipient) {
        const headers = 
            {
            "Content-Type": "application/json"
            };
        
        return fetch(`${baseUrl}/stripe/payment_intent/create_destination/guest`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({amount: centAmount, recipient: recipient})
    
        }).then(response => {
            return response.json();
        });
    },

    // update a payment_intent - new recipient info
    pi_update_destination_registered(payment_intent, recipient) {
        const headers = 
            {
            'x-access-token': authServices.authHeaders()['x-access-token'], 
            "Content-Type": "application/json"
            };
        
        return fetch(`${baseUrl}/stripe/payment_intent/update_destination/registered`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({pi_id: payment_intent, recipient: recipient})
    
        }).then(response => {
            return response.json();
        });
    },

    // update a payment_intent - new recipient info
    pi_update_destination_guest(payment_intent, recipient) {
        const headers = 
            {
            "Content-Type": "application/json"
            };
        
        return fetch(`${baseUrl}/stripe/payment_intent/update_destination/guest`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({pi_id: payment_intent, recipient: recipient})
    
        }).then(response => {
            return response.json();
        });
    },

    /* -----------------  admin --------------------------------- */

    // send an admin email notification
    notify_admin(message, data) {
        const headers = 
            {
            "Content-Type": "application/json"
            };
        
        return fetch(`${baseUrl}/users/admin/notification`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({message: message, data: data})

        }).then(response => {
            return response.json();
        });
    }, 

    // send an admin email notification
    admin_log_event(message) {
        const headers = 
            {
            "Content-Type": "application/json"
            };
        
        return fetch(`${baseUrl}/users/admin/log`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({message: message})

        }).then(response => {
            return response.json();
        });
    }

}

export default GGApi;